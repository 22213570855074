const roles = {
    "root":       1, // Root
    "operator":   2, // Демо
    "techWriter": 3, // Технический писатель
}

export default roles

export function roleName(role) {
    switch (role) {
        case roles.root: return 'Администратор'
        case roles.operator: return 'Оператор'
        case roles.techWriter: return 'Технический писатель'
        default: return 'Неопределенная роль'
    }
}