import { lazy, Suspense, useState, useEffect, useRef } from 'react'
import './App.css'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import SignOut from './views/pages/SignOut'
import LoaderIndicator from './components/UI/LoaderIndicator'
import TokenService from './services/token.service'
import { SnackbarProvider } from 'notistack'

// Containers
const DefaultLayout = lazy(() => import('./layouts/DefaultLayout/DefaultLayout'))


// Pages
const SignIn = lazy(() => import('./views/pages/SignIn'))

const NewVersion = lazy(() => import('./components/NewVersion'))

const initRole = TokenService.getRole()

export default function App() {

  const [isLoginIn, setIsLoginIn] = useState((TokenService.getLocalAccessToken()?.length && initRole) ? true : false)
  const [role, setRole] = useState(initRole)
  const [hasNewVersion, setHasNewVersion] = useState(false)

  const tidRef = useRef(null)

  const setUserStatus = (status, role) => {
    setIsLoginIn(status)
    setRole(role)
  }

  useEffect(() => {

    tidRef.current = setTimeout(checkVersion, 10000)

    async function checkVersion() {

      const response = await fetch("/version.json")

      if (response.ok) {
        const json = await response.json()

        if (process.env.REACT_APP_BUILD_NUMBER !== (json.buildVersion + "")) {
          setHasNewVersion(true)
        }

      }

      tidRef.current = setTimeout(checkVersion, 10000)
    }

  }, [])

  useEffect(() => () => {
    clearTimeout(tidRef.current)
  }, [])

  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <HashRouter>
          <Suspense fallback={<LoaderIndicator />}>
            {hasNewVersion ? <NewVersion /> : null}
            <Routes>
              {!isLoginIn
                ?
                <>
                  <Route exact path="/sign-in" element={<SignIn setUserStatus={setUserStatus} />} />
                  <Route path="*" element={<Navigate to="/sign-in" replace />} />
                </>
                :
                <>
                  <Route exact path="/sign-out" element={<SignOut setUserStatus={setUserStatus} />} />
                  <Route path="*" name="Home" element={<DefaultLayout role={role} />} />
                </>
              }
            </Routes>
          </Suspense>
        </HashRouter>
      </SnackbarProvider>
    </>
  )

}