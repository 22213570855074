import axios from "axios"
import TokenService from "./token.service"

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: false,
})

instance.interceptors.request.use(
    async (config) => {
        const token = TokenService.getLocalAccessToken()
        if (token) {

            // Если токен просрочен, то имитируем ошибку 100 для инициализации обновления токена через interceptors
            if (TokenService.isExpired(token) && config.url !== "/auth/refresh") {
                return Promise.reject({
                    config,
                    response: {
                        status: 100
                    }
                })
            }

            config.headers["Authorization"] = 'Bearer ' + token
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (res) => {
        return res
    },
    async (err) => {
        const originalConfig = err.config

        if (originalConfig.url !== "/auth/sign-in" && err.response) {

            // Access Token was expired
            if ((err.response.status === 100 || err.response.status === 401) && !originalConfig._retry) {

                if (originalConfig.url === "/auth/refresh") {
                    TokenService.removeUser()
                    window.location.replace("/")
                    return Promise.reject(err)
                }

                originalConfig._retry = true

                try {
                    const rs = await instance.post("/auth/refresh", {
                        refresh_token: TokenService.getLocalRefreshToken() ?? "0000000000000",
                    })
                    const { token, refresh_token } = rs.data
                    TokenService.updateLocalTokens(token, refresh_token)
                    return instance(originalConfig)
                } catch (_error) {
                    return Promise.reject(_error)
                }
            }
        }

        return Promise.reject(err)
    }
)

export const postFormData = (url, data) => {
    return instance({
        method: "post",
        url,
        data,
        headers: { "Content-Type": "multipart/form-data" }
    })
}

export const putFormData = (url, data) => {
    return instance({
        method: "put",
        url,
        data,
        headers: { "Content-Type": "multipart/form-data" }
    })
}


export default instance